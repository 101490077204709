<template>
  <div class="container">
    <div class="main">
      <div class="title">
        <img src="../../assets/mana/txt.png" alt="" />
      </div>
      <ul class="message">
        <li class="li">
          <div class="flg"></div>
          <div>尊敬的客户：</div>
        </li>
        <li>
          您好，感谢您参与商用车经销商运营情况调研！我们旨在收集各经销商的销量和库存数据，以便更好地了解市场动态，为行业发展提供参考。请您填写以下信息，包括经销商名称、数据月份、主营车型、当月销量和库存量。您的支持对我们至关重要，感谢您的参与！
        </li>
      </ul>
      <div class="info">
        <div class="user">
          <div><span style="color: #ee3024">*</span>1.经销商名称【填空】</div>
          <div v-if="userTip">必填项不能为空</div>
        </div>
        <input
          type="text"
          placeholder="请输入经销商名称"
          v-model.trim="params.name"
        />
        <div class="user">
          <div><span style="color: #ee3024">*</span>2.数据月份</div>
        </div>
        <div class="month" @click="show = true">
          <div>{{ params.date }}</div>
          <van-icon color="#333333" name="arrow-down" size="20px" />
        </div>
        <div class="user users">
          <div><span style="color: #ee3024">*</span>3.主营车型【单选】</div>
          <div v-if="typeTip">必填项不能为空</div>
        </div>
        <van-radio-group v-model="type">
          <van-radio icon-size="12px" name="1" shape="square">重卡</van-radio>
          <van-radio icon-size="12px" name="2" shape="square">中卡</van-radio>
          <van-radio icon-size="12px" name="3" shape="square">轻卡</van-radio>
        </van-radio-group>
        <div class="user">
          <div>
            <span style="color: #ee3024">*</span>4.当月销量（辆）【填空】
          </div>
          <div v-if="salesTip">必填项不能为空</div>
        </div>
        <input
          type="text"
          placeholder="请输入当月销量"
          v-model.trim="params.sales"
          @input="salesChange"
          @blur="salesChange"
        />
        <div class="user">
          <div>
            <span style="color: #ee3024">*</span>5.当月库存量（辆）【填空】
          </div>
          <div v-if="stockTip">必填项不能为空</div>
        </div>
        <input
          type="text"
          placeholder="请输入库存量"
          v-model.trim="params.inventory"
          @input="stockChange"
        />
      </div>
    </div>
    <div class="btnbox">
      <div class="btn" @click="submit">提交</div>
    </div>
    <van-popup v-model="show" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="confirm"
        @cancel="show = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { mgrIndex } from "@/utils/http.js";
import moment from "moment";
import { Toast } from "vant";
export default {
  data() {
    return {
      params: {
        name: "",
        date: "",
        vehicleType: "",
        sales: "",
        inventory: "",
      },
      type: "1",
      minDate: new Date(2009, 12),
      maxDate: new Date(),
      currentDate: new Date(),
      // month: "",
      userTip: false,
      salesTip: false,
      stockTip: false,
      typeTip: false,
      show: false,
    };
  },
  created() {
    const lastMonthDate = moment().subtract(1, "month");
    console.log(lastMonthDate.format("MM"));
    let month = lastMonthDate.format("MM").replace(/^0+/, '');
    this.params.date = `${lastMonthDate.format("YYYY")}年${month}月`;
  },
  mouted() {},
  methods: {
    salesChange(e) {
      this.$set(this.params, "sales", e.target.value.replace(/[^\d]/g, ""));
    },
    stockChange(e) {
      this.$set(this.params, "inventory", e.target.value.replace(/[^\d]/g, ""));
    },
    submit() {
      if (this.type == 1) {
        this.params.vehicleType = "重卡";
      }
      if (this.type == 2) {
        this.params.vehicleType = "中卡";
      }
      if (this.type == 3) {
        this.params.vehicleType = "轻卡";
      }
      if (!this.params.name) {
        this.userTip = true;
        Toast("经销商名称不能为空");
        return;
      }
      this.userTip = false;
      if (!this.params.vehicleType) {
        this.typeTip = true;
        Toast("主营车型不能为空");
        return;
      }
      this.typeTip = false;
      if (!this.params.sales) {
        this.salesTip = true;
        Toast("当月销量不能为空");
        return;
      }
      this.salesTip = false;
      if (!this.params.inventory) {
        this.stockTip = true;
        Toast("当月库存量不能为空");
        return;
      }
      this.stockTip = false;
      mgrIndex(this.params).then((res) => {
        if (res.data.resultStates != 0) {
          console.log(res.data.failureReason);
          if (res.data.failureReason == 3) {
            Toast("您已提交过该月份的数据，请勿重复提交");
          }
          if (res.data.failureReason == 2) {
            Toast("数据月份不正确，请重新选择");
          }
          if (res.data.failureReason == 1) {
            Toast("数据格式不正确，请重新填写");
          }
        } else {
          this.$router.replace("/success");
        }
      });
    },
    formatter(type, value) {
      if (type === "year") {
        return `${value}年`;
      } else if (type === "month") {
        return `${value}月`;
      }
      console.log(value, "-=-=-=-=-=");
      return value;
    },
    confirm(value) {
      let month = value.getMonth() + 1;
      // if (month < 10) {
      //   month = `0${month}`;
      // }
      this.params.date = `${value.getFullYear()}年${month}月`;
      this.show = false;
    },
  },
};
</script>
<style scoped>
.main {
  width: 375px;
  height: 732px;
  /* height: 668px; */
  background: url("../../assets/mana/bg.jpg") no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 40px 15px;
}

.title {
  font-family: ZhenyanGB, ZhenyanGB;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
}
.title > img {
  width: 310px;
  height: 23px;
}
.message {
  width: 345px;
  height: 126px;
  background: #ffffff;
  border-radius: 10px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #363636;
  box-sizing: border-box;
  padding: 10px 15px;
  margin-top: 30px;
}
.message > li:nth-of-type(2) {
  text-indent: 24px;
  line-height: 18px;
  margin-top: 4px;
}
.li {
  display: flex;
  align-items: center;
}
.flg {
  width: 1px;
  height: 11px;
  background: #051e77;
  border: 1px solid #051e77;
  margin-right: 3px;
}
.info {
  width: 345px;
  height: 327px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 15px;
}
.user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
}
.user > div:nth-of-type(2) {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #ee3024;
}
input {
  width: 315px;
  height: 26px;
  border-radius: 2px;
  border: 1px solid #999999;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 13px;
  text-indent: 6px;
  /* color: #333333; */
}
.van-radio-group {
  display: flex;
}
>>> .van-radio__label {
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 500;
  font-size: 13px;
  color: #333333;
  margin-left: 4px;
}
.van-radio {
  margin-right: 10px;
}
.users {
  margin-bottom: 4px;
}
.month {
  width: 315px;
  height: 26px;
  border-radius: 2px;
  border: 1px solid #999999;
  display: flex;
  align-items: center;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 500;
  font-size: 13px;
  color: #333333;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 6px;
  margin-top: 8px;
}
.btnbox {
  width: 375px;
  background: #ffffff;
  height: 65px;
  /* box-shadow: 0px 0px 3px 0px #e2e2e2; */
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  padding: 10px 0;
}
.btn {
  width: 270px;
  line-height: 45px;
  /* background: linear-gradient(#02007e 0%, #4981e0 100%); */
  background: linear-gradient(#4981e0 0%, #02007e 100%);
  box-shadow: 0px 1px 4px 0px #79b4ff;
  border-radius: 5px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  margin: 0 auto;
}
</style>